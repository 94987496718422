import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { UserRoleEnum } from '../types/role';
import { userApi } from '../api/userApi';

interface AdminGuardProps {
  children: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  
  const { user } = useAuth();
  const role: UserRoleEnum = userApi.toUserRoleEnum(user.role.name);

  if ((role === UserRoleEnum.Admin || role === UserRoleEnum.Employee)) {
    return <>{children}</>;
  }

  return <Navigate to="/401" />;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
