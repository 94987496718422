import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { UserRoleEnum } from '../types/role';
import { userApi } from '../api/userApi';

interface UserGuardProps {
  children: ReactNode;
}

const UserGuard: FC<UserGuardProps> = ({ children }) => {
  const { user } = useAuth();
  
  const role: UserRoleEnum = userApi.toUserRoleEnum(user.role.name);

  if (role !== UserRoleEnum.Company) {
    return <>{children}</>;
  }

  return <Navigate to="/401" />;
};

UserGuard.propTypes = {
  children: PropTypes.node
};

export default UserGuard;
