import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import { BrowserRouter } from 'react-router-dom';
// import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import './i18n';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
// import store from './store';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <HelmetProvider>
      {/* <ReduxProvider store={store}> */}
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              {/* <BrowserRouter> */}
                <AuthProvider>
                  <App />
                </AuthProvider>
              {/* </BrowserRouter> */}
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      {/* </ReduxProvider> */}
    </HelmetProvider>
  </StrictMode>
);
