import { Suspense, lazy } from 'react';
import type { RouteObject as PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import AdminGuard from './components/AdminGuard';
import LoadingScreen from './components/LoadingScreen';
import UserGuard from './components/UserGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/error/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

// Projects pages
const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjektBrowseTable')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
const ProjectEdit = Loadable(lazy(() => import('./pages/dashboard/ProjectEdit')));
const ProjectCurrent = Loadable(lazy(() => import('./pages/dashboard/ProjectCurrent')));

// User pages
const UserBrowse = Loadable(lazy(() => import('./pages/dashboard/UserBrowse')));
const UserCreate = Loadable(lazy(() => import('./pages/dashboard/UserCreate')));
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/UserEdit')));

// Parnter pages
const PartnerBrowse = Loadable(lazy(() => import('./pages/dashboard/PartnerBrowse')));
const PartnerCreate = Loadable(lazy(() => import('./pages/dashboard/PartnerCreate')));
const PartnerEdit = Loadable(lazy(() => import('./pages/dashboard/PartnerEdit')));

// Parnter pages
const CustomerCreate = Loadable(lazy(() => import('./pages/dashboard/CustomerCreate')));
const CustomerBrowse = Loadable(lazy(() => import('./pages/dashboard/CustomerBrowse')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));

// Contact persons
const ContactPersonsBrowse = Loadable(lazy(() => import('./pages/dashboard/ContactPersonsBrowse')));
const ContactPersonsCreate = Loadable(lazy(() => import('./pages/dashboard/ContactPersonsCreate')));
const ContactPersonsEdit = Loadable(lazy(() => import('./pages/dashboard/ContactPersonsEdit')));

// Location persons
const LocationBrowse = Loadable(lazy(() => import('./pages/dashboard/LocationBrowse')));
const LocationCreate = Loadable(lazy(() => import('./pages/dashboard/LocationCreate')));
const LocationEdit = Loadable(lazy(() => import('./pages/dashboard/LocationEdit')));

// Settings
const SettingsBrowse = Loadable(lazy(() => import('./pages/dashboard/SettingsBrowse')));
const SettingsEdit = Loadable(lazy(() => import('./pages/dashboard/SettingsEdit')));

// Default
const Imprint = Loadable(lazy(() => import('./pages/default/Imprint')));
const Dataprotection = Loadable(lazy(() => import('./pages/default/dataprotection')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'latest-projects',
        element: <ProjectCurrent />
      },
      {
        path: 'contactperson',
        children: [
          {
            path: '',
            element: <ContactPersonsBrowse />
          },
          {
            path: 'new',
            element: <ContactPersonsCreate />
          },
          {
            path: ':id',
            element: <ContactPersonsEdit />
          }
        ]
      },
      {
        path: 'location',
        children: [
          {
            path: '',
            element: <LocationBrowse />
          },
          {
            path: 'new',
            element: <LocationCreate />
          },
          {
            path: ':id',
            element: <LocationEdit />
          }
        ]
      },
      {
        path: 'projects',
        children: [
          {
            path: '',
            element: <ProjectBrowse />
          },
          {
            path: 'new',
            element: (
              <UserGuard>
                <ProjectCreate />
              </UserGuard>
            ),
          },
          {
            path: 'edit',
            children: [
              {
                path: ':id',
                element: <ProjectEdit />
              }
            ]
          },
          {
            path: ':id',
            element: <ProjectDetails />
          }
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <UserBrowse />
              </AdminGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AdminGuard>
                <UserCreate />
              </AdminGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <AdminGuard>
                <UserEdit />
              </AdminGuard>
            ),
          }
        ]
      },
      {
        path: 'partner',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <PartnerBrowse />
              </AdminGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AdminGuard>
                <PartnerCreate />
              </AdminGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <AdminGuard>
                <PartnerEdit />
              </AdminGuard>
            ),
          }
        ]
      },
      {
        path: 'customer',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <CustomerBrowse />
              </AdminGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AdminGuard>
                <CustomerCreate />
              </AdminGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <AdminGuard>
                <CustomerEdit />
              </AdminGuard>
            ),
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <SettingsBrowse />
              </AdminGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <AdminGuard>
                <SettingsEdit />
              </AdminGuard>
            ),
          }
        ]
      },
      {
        path: 'imprint',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <Imprint />
              </AdminGuard>
            ),
          }
        ]
      },
      {
        path: 'dataprotection',
        children: [
          {
            path: '',
            element: (
              <AdminGuard>
                <Dataprotection />
              </AdminGuard>
            ),
          }
        ]
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboard/"
            replace
          />
        )
      },
      {
        path: 'imprint',
        element: <Imprint />
      },
      {
        path: 'dataprotection',
        element: <Dataprotection />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
