import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Grid
} from '@mui/material';
import {
  LoginJWT
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import LanguagePopover from '../../components/dashboard/LanguagePopover';
import { Trans } from 'react-i18next';

const Login: FC = () => {
  const { platform } = useAuth() as any;

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 80,
                  width: 350
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={10}
                  >
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h4"
                    >
                      <Trans>Log in</Trans>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      <Trans>Log in on the (f)acts platform</Trans>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={2}
                    textAlign="right"
                  >
                    <LanguagePopover />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  <Trans>Forgot password</Trans>
                </Link>
                <Box>
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    to="/imprint"
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    <Trans>Imprint</Trans>
                  </Link>
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    to="/dataprotection"
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    <Trans>Dataprotection</Trans>
                  </Link>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
