import { config } from '../constants';
import { User } from '../types/user';
import { UserRoleEnum } from '../types/role';
import axios from 'axios';
import { BackendApi } from './backendApi';

export class UserApi extends BackendApi<User> {
  constructor() {
    super('users');
  }

  async register(data: object, image?: any): Promise<User> {
    const accessToken = window.localStorage.getItem('accessToken');

    let objectData;
    let formData;

    if (image) {
      formData = new FormData();
      formData.append('files.avatar', image[0], image[0].name);

      objectData = formData;
      formData.append('data', JSON.stringify(data));

    } else {
      objectData = data;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/register?populate=*`, objectData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('[Auth Api]: ', error.response);
          reject(new Error(error.response.data.error.message));
        });
    });
  }

  async updateWithAvatar(id: number, data: object, image?: any[]): Promise<User> {

    let formData;

    if (image) {
      if (image[0]) {
        formData = new FormData();
        formData.append('files.avatar', image[0], image[0].name);
      }
    }

    return super.update(id, data, formData);
  }

  async resetPassword(password: string, passwordConfirm: string): Promise<string> {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = {
      newPassword: password,
      confirmPassword: passwordConfirm,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/password`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.jwt);
        })
        .catch((error) => {
          console.error('[User Api]: ', error.response);
          reject(new Error(error.response.data.error.message));
        });
    });
  }

  roleObj(role: any) {
    return { id: role.id, name: role.name, type: role.type };
  }

  toUserRoleEnum(role: string) {
    if (role === 'Admin') {
      return UserRoleEnum.Admin;
    }

    if (role === 'Employee') {
      return UserRoleEnum.Employee;
    }

    if (role === 'Author') {
      return UserRoleEnum.Author;
    }

    if (role === 'Customer') {
      return UserRoleEnum.Customer;
    }

    if (role === 'Partner') {
      return UserRoleEnum.Partner;
    }

    return UserRoleEnum.Company;
  }
}

export const userApi = new UserApi();

