import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { ReactComponent as Logofile } from '../theme/Logo_CSR.svg';
import { ReactComponent as LogofileInverted } from '../theme/Logo_CSR_Inverted.svg';

interface LogoProps {
  sx?: SxProps<Theme>;
  invert?: boolean;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = ({ invert = false, ...props }) => {
  const SelectedLogo = invert ? LogofileInverted : Logofile;

  return (
    <LogoRoot
      {...props}
    >
      <SelectedLogo
        height="100%"
        version="1.1"
        viewBox="0 0 250 37"
        width="100%"
      />
    </LogoRoot>
  );
};

export default Logo;
