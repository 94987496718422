import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './theme/locales/en/translation.json';
import translationDE from './theme/locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .init({
    lng: window.localStorage.getItem('language') || 'de',
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false
    }
  });
