import { config } from '../constants';
import { MediaFile } from '../types/mediafile';

export class ImageApi {
 
  static imageUrl(image: MediaFile) {
    let url: string = null;

    if (image) {
      url = config.API_URL.replace(/\/api$/, '').concat(image.url);
    }

    return url;
  }

}