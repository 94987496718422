export interface UserRole {
  id: number;
  name: string;
  type: string;
}

export enum UserRoleEnum {
  Company = 0,
  Author = 1,
  Admin = 2,
  Employee = 3,
  Partner = 4,
  Customer = 5,
}
